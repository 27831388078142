import styled from 'styled-components'

const UploadForm = styled.div`
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  margin-top: 32px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`

const Button = styled.label`
  background: #1658F3;
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DisabledButton = styled(Button)`
  opacity: 0.3;
  cursor: default;
`

const File = styled.input`
  display: none;
`

const Title = styled.div`
  font-size: 14px;
  color: #616581;
`

export {UploadForm, Button, DisabledButton, Title, File}
