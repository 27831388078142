import React, { useState } from 'react'
import { connect } from 'react-redux'
import classes from './styles.module.scss'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { formatApiCall } from '../../../../helpers/formatAtomSave'
import { testApiRequest } from '../../api/atom'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'
import { alertError } from '../../../../api'
import Input from '../../../../uiKit/inputs/Input'
import { ApiCallType } from '../../../../models/MessageTypes'
import { NotFoundError } from '../../../../api'
import { LONG_MAX_VALUE, LONG_MAX_DIGITS } from '../../../../constants/numeric'

interface Props {
  apiCall: ApiCallType
  setResponse(data: any): void
  activeBotId: number
}

const TestRequestScreen: React.FC<Props> = ({ apiCall, setResponse, activeBotId }) => {
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState('')

  const handleTestRequest = async () => {
    const formattedApiCall = formatApiCall(apiCall)

    if (!formattedApiCall.url) {
      alertError('Enter request url')
      return
    }

    if (isInvalidUserId(userId)) {
      alertError(`User id ${userId} is outside the range of possible values.`)
      return
    }

    setLoading(true)
    try {
      const response = await testApiRequest(formattedApiCall, activeBotId, userId)
      if (response?.data) {
        setResponse(response.data)
      } else {
        setLoading(false)
        alertError(response?.error?.message || 'Failed. Try it later')
      }
    } catch (e) {
      setLoading(false)
      handleRequestError(e)
    }
  }

  const isInvalidUserId = userId => {
    const userIdBigInt = BigInt(userId)
    return userIdBigInt < BigInt('1') || userIdBigInt > LONG_MAX_VALUE
  }

  const handleRequestError = error => {
    if (error instanceof NotFoundError) {
      alertError(`Bot user with id ${userId} does not exist.`)
      setLoading(false)
    } else {
      alertError('Failed. Try it later')
    }
  }

  const validateAndSetUserId = event => {
    if (!event.target.value) {
      setUserId('')
    } else if (isNumber(event.target.value)) {
      setUserId(event.target.value)
    }
  }

  const isNumber = value => /^[\d]+$/.test(value)

  return (
    <div className={classes.container}>
      {loading ? (
        <LoaderSmall showLoader={true} />
      ) : (
        <>
          <img src="/images/platform/request.svg" alt="request" />
          <div className={classes.inputContainer}>
            <div className={classes.inputWrap}>
              <Input
                placeholder="User id"
                value={userId}
                maxLength={LONG_MAX_DIGITS}
                onChange={event => validateAndSetUserId(event)}
              />
            </div>
            <SubmitButton title="Test the request" onClick={handleTestRequest} disabled={!userId} />
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  activeBotId: state.activeBot?.id,
})
export default connect(mapStateToProps)(TestRequestScreen)
